import React from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ProjectMoreLinks from "Components/ProjectMoreLinks";
import useMediaQuery from "Hooks/useMediaQuery";
import { setProjectContext } from "Reducers/settingsMenu";

import FloatBox from "../FloatBox";
import NavigationMenu from "../NavigationMenu";

import {
  LiveButton,
  SettingsIcon,
  SettingsIconContainer,
  NavigationRightItemsContainer
} from "./Project.styles";

import type { Subscription, Project } from "@packages/client";

interface Props {
  toggleProjectWizard: () => void;
  project: Project;
  subscription: Subscription;
  organizationId: string;
  projectId: string;
}

const ProjectNavBar = ({
  toggleProjectWizard,
  subscription,
  project,
  organizationId,
  projectId,
  ...props
}: Props) => {
  const onDesktop = useMediaQuery("(min-width: 768px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const settingsLink = `/${organizationId}/${projectId}/-/settings`;

  const handleSettingsClicked = e => {
    e.stopPropagation();
    dispatch(setProjectContext());
    navigate(settingsLink);
  };

  return (
    <FloatBox>
      <NavigationMenu
        project={project}
        organizationId={organizationId}
        {...props}
      />
      {onDesktop && (
        <NavigationRightItemsContainer>
          {subscription && (
            <LiveButton
              project={project}
              subscription={subscription}
              organizationId={organizationId}
            />
          )}
          <SettingsIconContainer id="settings" onClick={handleSettingsClicked}>
            <SettingsIcon />
          </SettingsIconContainer>
          {project && (
            <ProjectMoreLinks
              from="project"
              project={project}
              subscription={subscription}
              toggleProjectWizard={toggleProjectWizard}
              isNav={true}
            />
          )}
        </NavigationRightItemsContainer>
      )}
    </FloatBox>
  );
};

export default ProjectNavBar;

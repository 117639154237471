import React, { useMemo } from "react";

import { useIntl } from "react-intl";
import { useLocation, useMatch, useParams } from "react-router-dom";

import { ENVIRONMENT_ID_FIELD } from "Constants/constants";
import Tooltip from "ds/Tooltip";
import useUrls from "Hooks/useUrls";
import getUrl from "Libs/getUrl";
import {
  selectMemberDetails,
  selectUserDetails
} from "Reducers/editingMember/memberData/memberData.selectors";
import {
  environmentsAsArraySelector,
  environmentTreeSelector
} from "Reducers/environment";
import { getFeatureFlag } from "Reducers/featureFlags";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import { teamDataByIdSelector } from "Reducers/organization/team/team.selectors";
import { projectsAllSelector } from "Reducers/project/project";
import { useAppSelector } from "Store/hooks";

import Node from "./Node";
import * as S from "./styles";

interface Props {
  environmentId?: string;
  hiddenXs: boolean;
  projectName?: string;
}

interface Item {
  title: string;
  path: string;
  id?: string | null;
  separator?: string;
}

const Breadcrumbs = ({ environmentId, hiddenXs, projectName }: Props) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const { organizationId, projectId, subscriptionId } = useParams();

  const usageBasedPagesEnabled = getFeatureFlag(
    "ENABLE_USAGE_BASED_BILLING_PAGES"
  );
  const organization = useAppSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );
  const teamPageMatch = useMatch("/:organizationId/-/settings/teams/:teamId");

  const projects = useAppSelector(projectsAllSelector);
  const currentProject = projects.find(project => project?.id === projectId);
  const member = useAppSelector(selectMemberDetails);
  const user = useAppSelector(selectUserDetails);
  const teamLabel = useAppSelector(state =>
    teamPageMatch?.params.teamId
      ? teamDataByIdSelector(state, { teamId: teamPageMatch.params.teamId })
          ?.label
      : undefined
  );

  const organizationBillingUrl = getUrl({
    key: "organization.billing",
    props: { organizationId: organizationId || "" }
  });

  const projectBillingUrl =
    organizationId && projectId && subscriptionId
      ? getUrl({
          key: "organization.project.usageBasedBilling",
          props: {
            organizationId: organizationId || "",
            projectId,
            subscriptionId
          }
        })
      : undefined;

  const organizationUsageBillingItem = {
    title: intl.formatMessage({ id: "breadcrumb.organization.usage.billing" }),
    path: organizationBillingUrl,
    id: "organization-billing"
  };

  const [createOrganizationUrl] = useUrls("newOrganization");

  const environments = useAppSelector(state =>
    environmentsAsArraySelector(state, {
      organizationId: organizationId!,
      projectId: projectId
    })
  );

  const environmentTree = useAppSelector(state =>
    environmentTreeSelector(state, {
      organizationId: organizationId!,
      projectId: projectId!
    })
  );

  const environmentPath = useMemo(() => {
    if (environmentTree.length === 0 || environments.length === 0) return [];

    const environment = environments?.find(env => env.id === environmentId);
    if (environment) {
      return [
        {
          id: encodeURIComponent(environment[ENVIRONMENT_ID_FIELD]),
          name: environment.name,
          title: environment.title
        }
      ];
    }

    return [];
  }, [environmentId, environmentTree.length, environments]);

  const getItems = () => {
    const isMetricsURL = projectId && pathname.match(/\/metrics$/);
    const isSettingsURL = new RegExp(
      `^/${organizationId}/${projectId}/-/settings`
    ).test(pathname);
    const items: Item[] = [];
    if (projectId) {
      if (usageBasedPagesEnabled && pathname.includes(projectBillingUrl!)) {
        items.push(organizationUsageBillingItem);
        items.push({
          title:
            currentProject?.title ??
            intl.formatMessage({
              id: "breadcrumb.organization.project.usage.billing"
            }),
          path: projectBillingUrl!,
          id: "project-billing"
        });
      } else {
        items.push({
          title: intl.formatMessage({ id: "breadcrumb.projects" }),
          path: organization?.hasLink("subscriptions")
            ? `/${organizationId}`
            : "/"
        });
        items.push({
          title:
            projectName || intl.formatMessage({ id: "breadcrumb.project" }),
          path: `/${organizationId}/${projectId}`,
          id: "project-navigation"
        });
      }
      const environmentPathLength = environmentPath.length - 1;
      environmentPath.forEach((env, i) => {
        items.push({
          title: env.title,
          path: `/${organizationId}/${projectId}/${env.id}`,
          separator:
            isMetricsURL && environmentPathLength === i ? undefined : "/",
          id: environmentPath.length === i + 1 ? "environment-navigation" : null
        });
      });

      if (isMetricsURL) {
        items.push({
          title: intl.formatMessage({ id: "metrics" }),
          path: pathname,
          separator: ">"
        });
      }

      if (isSettingsURL) {
        items.push({
          title: intl.formatMessage({ id: "settings" }),
          path: pathname,
          separator: ">"
        });
      }
      return items;
    }

    if (pathname === createOrganizationUrl) {
      items.push({
        title: intl.formatMessage({ id: "organization_settings" }),
        path: pathname
      });
    }

    const rootPath = `/-/users/${organizationId}`;
    const organizationRootPath = `/${organizationId}/-`;

    if (new RegExp(`^${rootPath}/(billing|settings|tickets)`).test(pathname)) {
      let paths = pathname
        .replace(rootPath, "")
        .split("/")
        .filter(elt => elt);

      // When the path is just /settings we want to show as a breadcrumb
      // just the tab name (my profile) instead of /account setttings/my profile
      if (paths.length === 1 && paths[0] === "settings") {
        paths = ["profile"];
      }

      paths.forEach((elt, i) => {
        const path = paths.slice(0, i + 1);
        // Manage path when last element is an ID
        const title =
          intl.messages[`breadcrumb.${path.join(".")}`] !== undefined
            ? intl.formatMessage(
                { id: `breadcrumb.${path.join(".")}` },
                {
                  elements: paths.length // hack to switch title if first element
                }
              )
            : intl.formatMessage(
                { id: `breadcrumb.${path.slice(0, i).join(".")}.id` },
                { id: elt }
              );

        items.push({
          title,
          path: `${rootPath}/${path.join("/")}`
        });
      });
      return items;
    }

    if (
      usageBasedPagesEnabled &&
      pathname.includes(organizationBillingUrl) &&
      organizationId
    ) {
      items.push(organizationUsageBillingItem);
    } else if (
      new RegExp(`^${organizationRootPath}/(billing|settings|tickets)`).test(
        pathname
      )
    ) {
      const paths = pathname
        .replace(organizationRootPath, "")
        .split("/")
        .filter(elt => elt);

      paths.forEach((elt, i) => {
        if (elt === "-") return;

        const path = paths.slice(0, i + 1);
        // Manage path when last element is an ID
        const title =
          intl.messages[`breadcrumb.organization.${path.join(".")}`] !==
          undefined
            ? intl.formatMessage(
                { id: `breadcrumb.organization.${path.join(".")}` },
                {
                  elements: paths.length // hack to switch title if first element
                }
              )
            : teamLabel
              ? teamLabel
              : intl.formatMessage(
                  { id: `breadcrumb.${path.slice(0, i).join(".")}.id` },
                  { id: elt }
                );

        if (user && title === member?.id) {
          items.push({
            title: `${user[member?.id].first_name} ${
              user[member?.id].last_name
            }`,
            path: `${organizationRootPath}/${member?.id}`
          });

          return items;
        }

        items.push({
          title,
          path: `${organizationRootPath}/${path.join("/")}`
        });
      });
      return items;
    }

    return items;
  };

  const items = getItems();

  return (
    <S.Wrapper aria-label="Breadcrumb" hiddenXs={hiddenXs} id="breadcrumb">
      <S.List>
        {items.map((item, i) => {
          const isLast = items.length === i + 1;

          return (
            <Node
              key={`item-${i}`}
              to={item.path}
              separator={!isLast ? item.separator || "chevron" : undefined}
              isActive={isLast}
              id={item.id}
            >
              <Tooltip tooltip={item.title} analyticId={`breadcrumb-${i}`}>
                {item.title}
              </Tooltip>
            </Node>
          );
        })}
      </S.List>
    </S.Wrapper>
  );
};

export default Breadcrumbs;

import React from "react";

import { NavigatationTabType } from "Components/SubNavBar/types";
import { BILLING_PATH_MATCH, SETTINGS_PATH_MATCH } from "Constants/constants";
import useDecodedParams from "Hooks/useDecodedParams";
import getUrl from "Libs/getUrl";
import { getFeatureFlag } from "Reducers/featureFlags";
import { organizationHasActiveCommitmentsSelector } from "Reducers/organization/discounts";
import { useIsOrganizationSecurityEnabled } from "src/organization/pages/settings/pages/security/hooks/useIsOrganizationSecurityEnabled";
import { useIsTeamsEnabled } from "src/organization/pages/settings/pages/teams/hooks/useIsTeamsEnabled";
import { useAppSelector } from "Store/hooks";

import * as S from "./styles";

import type { Organization } from "@packages/client";

const getAccountTabs = (organizationId: string): NavigatationTabType[] => {
  return [
    {
      name: "profile",
      route: `/-/users/${organizationId}/settings`
    },
    {
      name: "connected_accounts",
      route: `/-/users/${organizationId}/settings/accounts`,
      disabled: !getFeatureFlag("ENABLE_CONNECTED_ACCOUNTS_ROUTE")
    },
    {
      name: "security",
      route: `/-/users/${organizationId}/settings/security`,
      disabled: !getFeatureFlag("ENABLE_ACCOUNT_SECURITY_ROUTE")
    },
    {
      name: "tokens",
      route: `/-/users/${organizationId}/settings/tokens`
    },
    {
      name: "ssh-keys",
      route: `/-/users/${organizationId}/settings/ssh-keys`
    }
  ];
};

const getOrganizationSettingsTabs = (
  organizationId: string,
  organization: Organization | undefined,
  isTeamsEnabled: string,
  isOrganizationSecurityEnabled: boolean
): NavigatationTabType[] => {
  const usageBasedPagesEnabled = getFeatureFlag(
    "ENABLE_USAGE_BASED_BILLING_PAGES"
  );
  return [
    {
      name: "settings",
      route: `/${organizationId}/-/settings`
    },
    {
      name: "users",
      route: `/${organizationId}/-/settings/users`,
      disabled: !organization?.hasLink("members"),
      testPath: path => path.startsWith(`/${organizationId}/-/settings/users`)
    },
    {
      name: "teams",
      route: `/${organizationId}/-/settings/teams`,
      disabled:
        !["enabled", "disabled-promote-links"].includes(isTeamsEnabled) ||
        !organization?.hasLink("members"),
      testPath: path => path.startsWith(`/${organizationId}/-/settings/teams`)
    },
    {
      name: "organization_security",
      route: `/${organizationId}/-/settings/security`,
      disabled: !isOrganizationSecurityEnabled && !usageBasedPagesEnabled,
      testPath: path =>
        path.startsWith(`/${organizationId}/-/settings/security`)
    }
  ];
};

const getBillingTabs = (organizationId: string): NavigatationTabType[] => {
  const vouchersEnabled = getFeatureFlag("ENABLE_VOUCHERS");

  return [
    {
      name: "details",
      route: `/-/users/${organizationId}/billing`
    },
    {
      name: "plan",
      route: `/-/users/${organizationId}/billing/plan`
    },
    {
      name: "history",
      route: `/-/users/${organizationId}/billing/history`
    },
    {
      name: "vouchers",
      route: `/-/users/${organizationId}/billing/vouchers`,
      disabled: !vouchersEnabled
    }
  ];
};

const getOrganizationBillingTabs = (
  organizationId: string,
  organization: Organization | undefined,
  projectId: string,
  subscriptionId: string,
  hasActiveCommitments?: boolean
): NavigatationTabType[] => {
  const baseURL = `/${organizationId}/-/billing`;
  const usageBasedPagesEnabled = getFeatureFlag(
    "ENABLE_USAGE_BASED_BILLING_PAGES"
  );
  const vouchersEnabled = getFeatureFlag("ENABLE_VOUCHERS");

  const tabs: NavigatationTabType[] = [];
  if (organization?.hasLink("orders")) {
    const projectBillingUrl = getUrl({
      key: "organization.project.usageBasedBilling",
      props: { organizationId: organizationId || "", projectId, subscriptionId }
    });

    tabs.push({
      name: usageBasedPagesEnabled ? "organization_billing" : "details",
      route: baseURL,
      testPath: path => path.startsWith(projectBillingUrl)
    });
  }

  if (organization?.hasLink("estimate-subscription")) {
    tabs.push({
      name: "plan",
      route: `${baseURL}/plan`,
      disabled: usageBasedPagesEnabled
    });
  }

  if (usageBasedPagesEnabled && organization?.hasLink("payment-source")) {
    tabs.push({
      name: "project_billing_details", // this needs to be fix
      route: `${baseURL}/details`
    });
  }

  if (hasActiveCommitments) {
    tabs.push({
      name: "commitment",
      route: `${baseURL}/commitment`
    });
  }

  if (organization?.hasLink("orders")) {
    tabs.push({
      name: "history",
      route: `${baseURL}/history`,
      disabled: !getFeatureFlag("ENABLE_BILLING")
    });
  }

  if (organization?.hasLink("vouchers") && vouchersEnabled) {
    tabs.push({
      name: "vouchers",
      route: `${baseURL}/vouchers`
    });
  }

  return tabs;
};

interface Props {
  organizationId: string;
  currentPathName: string;
  organization?: Organization;
  username: string;
  tabConfig: NavigatationTabType[];
  projectId?: string;
}

interface GetTabsByPath {
  organizationId: string;
  currentPathName: string;
  organization?: Organization;
  username: string;
  isTeamsEnabled: string;
  projectId?: string;
  subscriptionId?: string;
  isOrganizationSecurityEnabled: boolean;
  hasActiveCommitments?: boolean;
}
const getTabsByPath = ({
  currentPathName,
  username,
  isOrganizationSecurityEnabled,
  organizationId,
  organization,
  isTeamsEnabled,
  projectId = "",
  subscriptionId = "",
  hasActiveCommitments
}: GetTabsByPath) => {
  if (BILLING_PATH_MATCH.test(currentPathName)) {
    return getBillingTabs(username);
  }
  if (SETTINGS_PATH_MATCH.test(currentPathName)) {
    return getAccountTabs(username);
  }
  if (new RegExp(`^/${organizationId}/-/settings`).test(currentPathName)) {
    return getOrganizationSettingsTabs(
      organizationId,
      organization,
      isTeamsEnabled,
      isOrganizationSecurityEnabled
    );
  }

  const organizationBillingUrl = getUrl({
    key: "organization.billing",
    props: { organizationId }
  });

  const projectBillingUrl = getUrl({
    key: "organization.project.usageBasedBilling",
    props: { organizationId, projectId, subscriptionId }
  });

  if (
    new RegExp(`^${organizationBillingUrl}|^${projectBillingUrl}`).test(
      currentPathName
    )
  ) {
    return getOrganizationBillingTabs(
      organizationId,
      organization,
      projectId,
      subscriptionId,
      hasActiveCommitments
    );
  }
};

const InsideNavBar = ({
  organizationId,
  currentPathName,
  organization,
  username,
  tabConfig,
  projectId
}: Props) => {
  const { subscriptionId } = useDecodedParams<{
    subscriptionId: string;
  }>();
  const isTeamsEnabled = useIsTeamsEnabled();
  const [isOrganizationSecurityEnabled] = useIsOrganizationSecurityEnabled(
    organization?.id || ""
  );

  const hasActiveCommitments = useAppSelector(state =>
    organizationHasActiveCommitmentsSelector(state, {
      organizationId: organization?.id
    })
  );

  return (
    <S.NavBar
      currentPathName={currentPathName}
      tabs={
        tabConfig ||
        getTabsByPath({
          currentPathName,
          isOrganizationSecurityEnabled: !!isOrganizationSecurityEnabled,
          username,
          organizationId,
          organization,
          isTeamsEnabled,
          projectId,
          subscriptionId,
          hasActiveCommitments
        })?.filter(tab => !tab.disabled)
      }
    />
  );
};

export default InsideNavBar;

import React, { useEffect, useMemo, useState } from "react";

import { BackupIcon, BranchIcon, MergeIcon, SyncIcon } from "@packages/ds";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";

import GoLiveButton from "Containers/NavBar/Environment/GoLiveButton";
import FloatBox from "Containers/NavBar/FloatBox";
import NavigationMenu from "Containers/NavBar/NavigationMenu";
import { ActionButton } from "ds/Button";
import Tooltip from "ds/Tooltip";
import useMediaQuery from "Hooks/useMediaQuery";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";
import useUrls from "Hooks/useUrls";
import { breakpoints } from "Libs/theme";
import { capitalize, isDg2 } from "Libs/utils";
import { environmentSelector } from "Reducers/environment";
import {
  selectHasEnterpriseDeployment,
  selectUsedEnvironments
} from "Reducers/environment/environment.selectors";
import {
  selectFeatureSourceOperationsEnabled,
  selectFeatureSourceRepositoryTooltipEnabled
} from "Reducers/featureFlags/featureFlags.selectors";
import { integrationsSelector } from "Reducers/integration";
import { organizationProfileSelector } from "Reducers/organization/profile";
import { setEnvironmentsContext } from "Reducers/settingsMenu";
import { subscriptionSelector } from "Reducers/subscription";
import { useAppSelector } from "Store/hooks";

import EnvironmentBackupModal from "./BackupModal";
import {
  ActionButtons,
  SettingsIcon,
  SettingsIconContainer,
  Wrapper
} from "./Environment.styles";
import MoreEnvironmentAction, {
  MORE_ACTION_ID
} from "./moreActions/MoreEnvironmentAction";
import { SourceOperations } from "./sourceOperations";

import type { Project, Environment } from "@packages/client";

// this controls when to show the text in the action buttons
// for this to work the text must be wrapped in a <span> tag
const showCompleteButtonsMQ = `(min-width: 1060px)`;

interface Props {
  project: Project;
  environment: Environment;
  organizationId: string;
  projectId: string;
  environmentId: string;
  currentPathName: string;
}

const EnvironmentNavBar = ({
  project,
  environment,
  organizationId,
  projectId,
  environmentId,
  currentPathName
}: Props) => {
  const intl = useIntl();
  const envMatch = useMatch("/:organizationId/:projectId/:environmentId");

  const dispatch = useDispatch();
  const sourceOperationsEnabled = useAppSelector(
    selectFeatureSourceOperationsEnabled
  );
  const usedEnvironments = useSelectorWithUrlParams(selectUsedEnvironments);

  const subscriptionToCheckEnterpriseTag = useAppSelector(state => {
    return subscriptionSelector(state, {
      organizationId,
      projectId,
      id: project?.subscription_id
    });
  });
  const [canCreateBranch, setCanCreateBranch] = useState(true);
  const [tooltipId, setTooltipId] = useState<string>("cannot_create_branch");
  const [openBackupModal, setBackupModalState] = useState(false);
  const [openSourceOperation, setOpenSourceOperation] = useState(false);
  const [isRuntimeOperation, setIsRuntimeOperation] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const enableSourceRepoTooltip = useAppSelector(
    selectFeatureSourceRepositoryTooltipEnabled
  );

  const showActionButtons = useMediaQuery(`(min-width: ${breakpoints[1]})`);

  // We only show the golive button on main env so no need to move
  // action buttons into more env dropdown if we are not in the main env
  const showSyncButtonInMoreActionsDropdown =
    useMediaQuery(`(max-width: 880px)`) && environment.is_main;
  const showMergeButtonInMoreActionsDropdown =
    useMediaQuery(`(max-width: 826px)`) && environment.is_main;
  const showBranchButtonInMoreActionsDropdown =
    useMediaQuery(`(max-width: 780px)`) && environment.is_main;

  const navigate = useNavigate();

  const settingsLink = `/${organizationId}/${projectId}/${encodeURIComponent(
    environmentId
  )}/settings`;

  const toggleBackupModal = () => {
    setBackupModalState(!openBackupModal);
  };

  const [
    mergeActionUrl,
    branchActionUrl,
    syncActionUrl,
    redeployActionUrl,
    resumeActionUrl
  ] = useUrls([
    "organization.project.environment.actions.merge",
    "organization.project.environment.actions.branch",
    "organization.project.environment.actions.sync",
    "organization.project.environment.actions.redeploy",
    "organization.project.environment.actions.resume"
  ]);

  const handleMergeClicked = e => {
    if (environment.hasLink("#merge")) {
      e.preventDefault();
      (document.activeElement as HTMLElement).blur();
      navigate(mergeActionUrl);
    }
  };

  const handleBranchClicked = e => {
    if (environment.hasLink("#branch")) {
      e.stopPropagation();
      (document.activeElement as HTMLElement).blur();
      navigate(branchActionUrl);
    }
  };

  const handleSyncClicked = e => {
    if (environment.hasLink("#synchronize")) {
      e.stopPropagation();
      (document.activeElement as HTMLElement).blur();
      navigate(syncActionUrl);
    }
  };

  const handleSettingsClicked = e => {
    e.stopPropagation();
    dispatch(setEnvironmentsContext(environmentId));
    navigate(settingsLink);
  };

  const onClickMoreAction = props => {
    switch (props.id) {
      case MORE_ACTION_ID.BACKUP:
        if (environment.hasLink("#backup")) {
          props.event.preventDefault();
          (document.activeElement as HTMLElement).blur();
          toggleBackupModal();
        }
        break;
      case MORE_ACTION_ID.BRANCH:
        handleBranchClicked(props.event);
        break;
      case MORE_ACTION_ID.MERGE:
        handleMergeClicked(props.event);
        break;
      case MORE_ACTION_ID.REDEPLOY:
        environment.hasLink("#redeploy") && navigate(redeployActionUrl);
        props.event.preventDefault();
        break;
      case MORE_ACTION_ID.RESUME:
        navigate(resumeActionUrl);
        props.event.preventDefault();
        break;
      case MORE_ACTION_ID.SOURCE_OPS:
        setOpenSourceOperation(!openSourceOperation);
        setIsRuntimeOperation(props?.isRuntime);
        props.event.preventDefault();
        break;
      case MORE_ACTION_ID.SYNC:
        props.event.preventDefault();
        handleSyncClicked(props.event);
        break;
      case MORE_ACTION_ID.DELETE_ENVIRONMENT:
      //TODO DELETE delete environment
    }
  };

  const organizationProfile = useAppSelector(state =>
    organizationProfileSelector(state, { organizationId })
  );

  useEffect(() => {
    if (!environment.hasLink("#branch")) {
      setTooltipId("cannot_create_branch");
      setCanCreateBranch(false);
      return;
    }

    if (organizationProfile?.resources_limit) {
      const limitEnv =
        organizationProfile?.resources_limit?.limit?.environments ?? 0;
      const usedEnv = Math.max(
        organizationProfile?.resources_limit?.used.projects.reduce(
          (acc, elt) => (acc += elt?.environments ?? 0),
          0
        ),
        usedEnvironments?.length
      );
      !(limitEnv > usedEnv) && setTooltipId("branch_max_env");
      setCanCreateBranch(limitEnv > usedEnv);
      return;
    }

    setCanCreateBranch(true);
  }, [environment, organizationProfile, usedEnvironments]);

  const parentEnvironment = useAppSelector(state =>
    environmentSelector(state, {
      organizationId,
      projectId,
      environmentId: environment?.parent ?? undefined
    })
  );

  const enterpriseDeployment = useSelectorWithUrlParams(
    selectHasEnterpriseDeployment
  );

  //TODO: This should be done in GIT, but DG2 for Adobe is critical, once done in Git, remove isDg2AndCanNotSync check
  //Should be all done via HAL links: environment.hasLink("#synchronize")
  const syncProductionType = environment?.type === "production";

  // this controls when to show the text in the action button
  // for this to work the text must be wrapped in a <span> tag
  const showCompleteButtonMQ = `(min-width: ${breakpoints[1]})`;

  const syncProductionToChildEnvironment =
    environment?.type === "staging" && parentEnvironment?.type === "production";

  const syncStagingToChildEnvironment =
    environment?.type === "development" &&
    parentEnvironment?.type === "staging";

  const { enterprise_tag } = subscriptionToCheckEnterpriseTag || {};

  const isDg2Project = isDg2(enterprise_tag, enterpriseDeployment);

  const isDg2AndCanNotSync =
    isDg2Project &&
    (syncProductionType ||
      syncProductionToChildEnvironment ||
      syncStagingToChildEnvironment);

  const syncDisabled =
    !environment.hasLink("#synchronize") || isDg2AndCanNotSync;

  const BranchTooltip = canCreateBranch
    ? React.Fragment
    : ({ children }: { children: React.ReactNode }) => (
        <Tooltip
          tooltip={intl.formatMessage({ id: tooltipId })}
          analyticId={tooltipId}
        >
          {children}
        </Tooltip>
      );

  const SourceRepositoryTooltip = ({
    children
  }: {
    children: React.ReactNode;
  }) => (
    <Tooltip tooltip={intl.formatMessage({ id: "source_repository_tooltip" })}>
      {children}
    </Tooltip>
  );

  const integration = useSelectorWithUrlParams(integrationsSelector);
  const hasSourceIntegration = useMemo(
    () => integration.some(e => e.category === "git"),
    [integration]
  );

  // If the customer has the source repo tooltip enabled and they have any integration
  // that is of category of git, all the environment source actions buttons will be
  // disabled regardless of the sync, merge and branch permission on this environment
  const disableGitActionButtons =
    enableSourceRepoTooltip && hasSourceIntegration;

  const getSourceRepositoryTooltip = (enabledTooltip: boolean) =>
    disableGitActionButtons && enabledTooltip
      ? SourceRepositoryTooltip
      : React.Fragment;

  const BranchSourceRepositoryTooltip =
    getSourceRepositoryTooltip(!canCreateBranch);
  const MergeSourceRepositoryTooltip = getSourceRepositoryTooltip(
    !environment.hasLink("#merge")
  );
  const SyncSourceRepositoryTooltip = getSourceRepositoryTooltip(syncDisabled);

  return (
    <Wrapper>
      <FloatBox>
        <NavigationMenu
          project={project}
          environment={environment}
          environmentId={environmentId}
          organizationId={organizationId}
        />
        {showActionButtons && (
          <ActionButtons
            className={`action-buttons${
              environment && environment.status === "inactive"
                ? " inactive"
                : ""
            }`}
          >
            {envMatch && (
              <React.Fragment>
                {!showBranchButtonInMoreActionsDropdown && (
                  <BranchSourceRepositoryTooltip>
                    <BranchTooltip>
                      <ActionButton
                        analyticId="branch"
                        id="environment-branch-btn"
                        onClick={handleBranchClicked}
                        disabled={!canCreateBranch || disableGitActionButtons}
                        aria-label={intl.formatMessage({ id: "branch" })}
                        expandWhen={showCompleteButtonsMQ}
                      >
                        <BranchIcon />
                        <span>
                          {capitalize(intl.formatMessage({ id: "branch" }))}
                        </span>
                      </ActionButton>
                    </BranchTooltip>
                  </BranchSourceRepositoryTooltip>
                )}

                {!showMergeButtonInMoreActionsDropdown && (
                  <MergeSourceRepositoryTooltip>
                    <ActionButton
                      analyticId="merge"
                      onClick={handleMergeClicked}
                      disabled={
                        !environment.hasLink("#merge") ||
                        disableGitActionButtons
                      }
                      aria-label={intl.formatMessage({ id: "merge" })}
                      expandWhen={showCompleteButtonsMQ}
                    >
                      <MergeIcon />{" "}
                      <span>
                        {capitalize(intl.formatMessage({ id: "merge" }))}
                      </span>
                    </ActionButton>
                  </MergeSourceRepositoryTooltip>
                )}

                {!showSyncButtonInMoreActionsDropdown && (
                  <SyncSourceRepositoryTooltip>
                    <ActionButton
                      id="environment-synchronize-btn"
                      onClick={handleSyncClicked}
                      disabled={syncDisabled || disableGitActionButtons}
                      aria-label={intl.formatMessage({ id: "sync" })}
                      expandWhen={showCompleteButtonsMQ}
                      analyticId="sync"
                    >
                      <SyncIcon />{" "}
                      <span>
                        {capitalize(intl.formatMessage({ id: "sync" }))}
                      </span>
                    </ActionButton>
                  </SyncSourceRepositoryTooltip>
                )}

                <GoLiveButton />
              </React.Fragment>
            )}

            <EnvironmentBackupModal
              isOpen={openBackupModal}
              closeModal={toggleBackupModal}
            />
            {sourceOperationsEnabled && (
              <SourceOperations
                organizationId={organizationId}
                projectId={projectId}
                environmentId={environmentId}
                isOpen={openSourceOperation}
                onClose={() => setOpenSourceOperation(false)}
                isRuntime={isRuntimeOperation}
              />
            )}

            {new RegExp(
              `/${organizationId}/${projectId}/${environmentId}/(backups|activities)`
            ).test(decodeURIComponent(currentPathName)) && (
              <>
                <ActionButton
                  analyticId="backup"
                  disabled={environment?.status === "inactive"}
                  onClick={() => setOpenModal(true)}
                  aria-label={intl.formatMessage({ id: "backup" })}
                  expandWhen={showCompleteButtonMQ}
                >
                  <BackupIcon />
                  {intl.formatMessage({ id: "backup" })}
                </ActionButton>
                <EnvironmentBackupModal
                  isOpen={openModal}
                  closeModal={() => setOpenModal(false)}
                />
              </>
            )}

            <SettingsIconContainer
              id="settings"
              onClick={handleSettingsClicked}
            >
              <SettingsIcon />
            </SettingsIconContainer>

            <MoreEnvironmentAction
              onClick={onClickMoreAction}
              environment={environment}
            />
          </ActionButtons>
        )}
      </FloatBox>
    </Wrapper>
  );
};
export default EnvironmentNavBar;

import React from "react";

import { useIntl } from "react-intl";

import HeaderMenuMobile from "Containers/NavBar/HeaderMenu/mobile";
import { FloatingContainer } from "Containers/NavBar/styles";
import { CreateOrgSeparator } from "Containers/NavBar/UserMenu/styles";
import { getSupportUrl } from "Libs/getUrl/getUrl";
import { docsUrl } from "Libs/utils";

import * as S from "./styles";

import type { Me } from "@packages/client";

interface Props {
  user: Me;
  accountUrl?: string;
  setIsOpen: (boolean) => void;
  isOpen?: boolean;
  setIsHelpMobileMenu: (boolean) => void;
}

const HelpMobileMenu = ({
  user,
  accountUrl,
  setIsOpen,
  isOpen,
  setIsHelpMobileMenu
}: Props) => {
  const intl = useIntl();

  const submitIdeaUrl = intl.messages["links.submit_idea"];
  const discordUrl = intl.messages["links.discord"];

  const supportUrl = getSupportUrl({
    accountUrl,
    username: user.username
  });

  return (
    <S.Layout>
      <FloatingContainer>
        <>
          <HeaderMenuMobile
            onClickBack={() => setIsHelpMobileMenu(false)}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
          <S.UserMenu>
            <li>
              <S.CustomMenuLink
                label={intl.formatMessage({ id: "menu.documentation" })}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setIsOpen(false)}
                url={docsUrl}
              />
            </li>
            <li>
              <S.CustomMenuLink
                label={intl.formatMessage({ id: "menu.support" })}
                url={supportUrl || ""}
              />
            </li>

            {discordUrl && (
              <li>
                <S.CustomMenuLink
                  label={intl.formatMessage({ id: "help.discord" })}
                  url={discordUrl as string}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setIsOpen(false)}
                />
              </li>
            )}

            {submitIdeaUrl && (
              <li>
                <CreateOrgSeparator />
                <li>
                  <S.CustomMenuLink
                    url={submitIdeaUrl as string}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: "help.submit_idea" })}
                  </S.CustomMenuLink>
                </li>
              </li>
            )}
          </S.UserMenu>
        </>
      </FloatingContainer>
    </S.Layout>
  );
};

export default HelpMobileMenu;
